<template>
  <div style="width: 100%">
    <div class="container">
      <!-- 头部 -->
      <div class="header">
        <router-link to="/"
          ><img src="../../static/img/Vector.png" alt=""
        /></router-link>
        <p>停车位预约</p>
      </div>
    </div>
    <div class="logo">
      <img src="../../static/img/Logo.png" alt="" />
      <p>预约失败</p>
      <p style="color: #0e0e0e; font-size: 16px; margin-top: 10px">
        {{ text }}
      </p>
    </div>
    <div class="nextStepBtn disableItem" @click="backToReservation">
      修改时间段
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      text: "",
    };
  },
  methods: {
    backToReservation() {
      this.$router.push({
        name:'ParkingReservation',
      })
    },
  },
  created() {
    console.log(this.$route.params.data);
    this.text = this.$route.params.data.msg == "有重复预约" ? "该号码已有预约" : "该时间段车位已满，请重新预约车位"
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.container {
  position: relative;
  transition: all 0.5s;
}

.header {
  position: relative;
  background-color: #1f89eb;
  height: 50px;
  width: 100%;
  /* margin-bottom: 20px; */
}

.header img {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(0%, -50%) rotateY(180deg);
  height: 16px;
}

.header > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.logo {
  height: 300px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #BABABA;
  position: relative;
  top: 200px;
}

.logo img {
  height: 220px;
  display: block;
  margin: auto;
}

.info {
  margin-top: 30px;
  font-size: 16px;
}

.infoItem {
  height: 25px;
  margin-bottom: 30px;
  position: relative;
}

.infoItem p:nth-child(1) {
  font-weight: bold;
  position: absolute;
  left: 10%;
}

.infoItem p:nth-child(2) {
  position: absolute;
  left: 35%;
}

.disableItem {
  background: #ffffff;
  border: 1px solid #f4f4f5;
  color: #dbdbdb;
}

.nextStepBtn {
  position: absolute;
  bottom: 40px;
  left: 10%;
  width: 80%;
  height: 40px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
/*   color: #ffffff;
  background: #1f89eb; */
  border-radius: 50px;
}
</style>